import React, { useState, useRef } from 'react';
import bot from './assets/bot.svg';
import user from './assets/user.svg';
import { ReactMic } from "react-mic";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  TextField,
  IconButton,
  Box,
  Typography,
  MenuItem,
  Select
} from "@mui/material";
import styled from "@emotion/styled";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  DownOutlined,
  UserOutlined,
  AliwangwangFilled,
  GitlabFilled,
  CrownTwoTone,
  FileFilled,
  SmileTwoTone,
  CheckCircleTwoTone,
  FireTwoTone,
  FilePdfTwoTone,
  DownCircleTwoTone,
  SendOutlined,
  FileAddOutlined,
  SettingOutlined,
  SmileOutlined
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Space,
  Tooltip,
  message,
  Divider,
  Input,
  Cascader
} from "antd";


const styles = {
  app: {
    width: '100vw',
    height: '100vh',
    background: '#343541',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chatContainer: {
    flex: 1,
    width: '100%',
    maxWidth: '1280px',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    paddingBottom: '20px',
    scrollBehavior: 'smooth',
    margin: '0 auto',
    position: 'relative',
  },
  chatContainerScrollbar: {
    display: 'none',
  },
  wrapper: {
    width: '100%',
    padding: '15px',
  },
  ai: {
    background: '#40414f',
  },
  chat: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '10px',
  },
  profile: {
    width: '36px',
    height: '36px',
    borderRadius: '5px',
    background: '#5436da',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  aiProfile: {
    background: '#10a37f',
  },
  profileImg: {
    width: '60%',
    height: '60%',
    objectFit: 'contain',
  },
  message: {
    flex: 1,
    color: '#dcdcdc',
    fontSize: '20px',
    maxWidth: '100%',
    overflowX: 'scroll',
    whiteSpace: 'pre-wrap',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  messageScrollbar: {
    display: 'none',
  },
  form: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '10px',
    background: '#40414f',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  textarea: {
    width: '100%',
    color: '#fff',
    fontSize: '18px',
    padding: '10px',
    background: 'transparent',
    borderRadius: '5px',
    border: 'none',
    outline: 'none',
  },
  button: {
    outline: 0,
    border: 0,
    cursor: 'pointer',
    background: 'transparent',
  },
  formImg: {
    width: '30px',
    height: '30px',
  },
};


export default function TextAI() {

  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const aiMessageRef = useRef(null); // Add this line to create a reference

  let loadInterval; // Add this line to create a variable to store the interval

  // Function to show 3 dots while the AI is typing
  function loader() {
    if (aiMessageRef.current) {
      aiMessageRef.current.textContent = '';

      loadInterval = setInterval(() => {
        if (aiMessageRef.current) {
          aiMessageRef.current.textContent += '.';

          if (aiMessageRef.current.textContent === '....') {
            aiMessageRef.current.textContent = '';
          }
        }
      }, 300);
    }
  }
  
  // Function to type the AI message letter by letter
  function typeText(element, text) {
    let index = 0;

    let interval = setInterval(() => {
      if (index < text.length) {
        element.innerHTML += text.charAt(index);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 20);
  }

  const generateUniqueId = () => {
    const timestamp = Date.now();
    const randomNumber = Math.random();
    const hexadecimalString = randomNumber.toString(16);

    return `id-${timestamp}-${hexadecimalString}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // User message
    const userMessage = { isAi: false, value: inputValue, uniqueId: generateUniqueId() };
    setMessages([...messages, userMessage]);

    setInputValue('');

    // AI message
    const uniqueId = generateUniqueId();
    const aiMessage = { isAi: true, value: '...', uniqueId };
    setMessages([...messages, userMessage, aiMessage]);

    loader(); // Call the loader function here to show 3 dots while the AI is typing

    const response = await fetch(process.env.REACT_APP_AI_ASSISTANT_FASTAPI_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_AI_ASSISTANT_FASTAPI_KEY,
      },
      body: JSON.stringify({ message: userMessage.value }),
    });

    clearInterval(loadInterval); // Clear the interval when the response is received

    if (response.ok) {
      const data = await response.json();
      const parsedData = data.output_message.trim();
      typeText(aiMessageRef.current, parsedData); // Use typeText function here
      const updatedAiMessage = { ...aiMessage, value: parsedData };
      setMessages((prevMessages) =>
        prevMessages.map((message) => (message.uniqueId === uniqueId ? updatedAiMessage : message))
      );
    } else {
      const err = await response.text();
      alert(err);
      const errorAiMessage = { ...aiMessage, value: 'Something went wrong' };
      setMessages((prevMessages) =>
        prevMessages.map((message) => (message.uniqueId === uniqueId ? errorAiMessage : message))
      );
    }
  };

  return (
    <div id="app" style={styles.app}>
      <div id="chat_container" style={styles.chatContainer}>
        {messages.map((message) => (
          <div
            key={message.uniqueId}
            style={{ ...styles.wrapper, ...(message.isAi ? styles.ai : {}) }}
          >
            <div style={styles.chat}>
              <div
                style={{
                  ...styles.profile,
                  ...(message.isAi ? styles.aiProfile : {}),
                }}
              >
                <img src={message.isAi ? bot : user}
                  alt={message.isAi ? 'bot' : 'user'}
                  style={styles.profileImg}
                />
              </div>
              <div
                style={styles.message}
                ref={message.isAi ? aiMessageRef : null} // Assign the ref to the AI message element
              >
                {message.value}
              </div>
            </div>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} style={styles.form}>
        <textarea
          name="prompt"
          rows="1"
          cols="1"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Ask your AI assistant anything..."
          style={styles.textarea}
        ></textarea>
        <Button
          type="primary"
          color = "primary"
          shape="circle"
          icon={<SendOutlined />}
          htmlType="submit"
          style={styles.button}
        />
      </form>
    </div>
  );
};
